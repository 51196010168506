<template>
    <div class="home">
        <!-- table -->
        <!-- 此处这么处理是因为：当弹框的时候，多选框会显示 -->
        <template v-if="!presentVisible"> 
            <div class="tablewrapper">
                <el-table ref="multipleTable" :data="list.list" tooltip-effect="dark" @selection-change="handleSelectionChange" stripe>
                    <!-- <el-table-column type="selection" width="50"></el-table-column> -->
                    <el-table-column type="index" width="40" label="#"></el-table-column>
                    <el-table-column prop="typeZh" label="类别" min-width="60" align="center"></el-table-column>
                    <el-table-column prop="number1" label="数据1" min-width="60" align="center"></el-table-column>
                    <el-table-column prop="number2" label="数据2" min-width="60" align="center"></el-table-column>
                    <el-table-column prop="number3" label="数据3" min-width="60" align="center"></el-table-column>
                    <!-- <el-table-column prop="type" label="职位类型" min-width="60" align="center"></el-table-column>
                    <el-table-column prop="city" label="城市" min-width="100" align="center"></el-table-column>
                    <el-table-column prop="number" label="招聘人数" min-width="100" align="center" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="displayZh" label="展示状态" min-width="50" align="center"></el-table-column> -->
                    <el-table-column prop="updatedAt" label="更新时间" :formatter="formatter" min-width="90" align="center" show-overflow-tooltip></el-table-column>

                    <el-table-column label="操作" min-width="120" align="center">
                        <template #default="scope">
                            <el-button size="mini" type="primary" @click="handleEdit(scope.$index, scope.row)">内容编辑</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </template>

        <!-- 编辑 -->
        <!-- 新增或编辑个人信息 -->
        <el-dialog :title="dialogTitle" v-model="dialogVisible">
            <el-form label-width="100px" :model="newQuery" ref="addQuery" :rules="rules">
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="类别：" prop="typeZh">
                            <el-input v-model="newQuery.typeZh" disabled></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="标题1：" prop="title1">
                            <el-input v-model="newQuery.title1"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="数据1：" prop="number1">
                            <el-input-number v-model="newQuery.number1" :min="1"></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="单位1：" prop="unit1">
                            <el-input v-model="newQuery.unit1"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="标题2：" prop="title2">
                            <el-input v-model="newQuery.title2"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="数据2：" prop="number2">
                            <el-input-number v-model="newQuery.number2" :min="1"></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="单位2：" prop="unit2">
                            <el-input v-model="newQuery.unit2"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="标题3：" prop="title3">
                            <el-input v-model="newQuery.title3"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="数据3：" prop="number3">
                             <el-input-number v-model="newQuery.number3" :min="1"></el-input-number>
                            <!-- <el-input v-if="newQuery.type == 1" type="number" v-model="newQuery.number3">
                                <template #append>%</template>
                            </el-input>
                            <el-input-number v-else v-model="newQuery.number3" :min="1"></el-input-number> -->
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="单位3：" prop="unit3">
                            <el-input v-model="newQuery.unit3"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div class="dialog-footer">
                <el-button @click="dialogVisible = false" style="width: 100px;">取消</el-button>
                <el-button type="primary" @click="handleSure" :loading="loadingOne" style="width: 100px; margin-left: 40px;">保存</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
// import {fmt} from '@/assets/utils';
const Util = require('@/assets/utils');
import {
    getAllNumber as get,
    updateNumber as update,
} from "@/api/api";
const code = 200
export default {
    name: "业务领域",
    data(){
        return{
            query: {
                page: 1,
                size: 10, 
                name: '',
                type: '',
                display: ''
            },
            addQuery: {
                id: '',
                type: '',
                title1: '',
                number1: '',
                unit1: '',
                title2: '',
                number2: '',
                unit2: '',
                title3: '',
                number3: '',
                unit3: '',
            },
            rules:{
                title1: [{required: true, message: '请输入标题', trigger: 'blur'}],
                number1: [{required: true, message: '请输入数值', trigger: 'blur'}],
                title2: [{required: true, message: '请输入标题', trigger: 'blur'}],
                number2: [{required: true, message: '请输入数值', trigger: 'blur'}],
                title3: [{required: true, message: '请输入标题', trigger: 'blur'}],
                number3: [{required: true, message: '请输入数值', trigger: 'blur'}],
            },
            newQuery: {},
            list:{},
            dialogTitle: '',
            dialogVisible: false,
            loadingOne: false,
        }
    },
    computed:{
    },
    methods:{
        // 时间格式化
        formatter(row,column){
            return Util.fmt(row.updatedAt)
        },
        getSearchList(){
            this.loadingOne = true
            get(this.query).then(res =>{
                if(res.data.code == code){
                    this.list = res.data.data
                }else{
                    this.$message({
                        type: 'error',
                        message: res.data.msg
                    })
                }
                this.loadingOne = false
            }).catch(() =>{
                this.$message({
                    type: 'error',
                    message:"查询失败,请重试或者联系管理员！！"
                })
                this.loadingOne = false
            })
        },
        // 编辑
        handleEdit(index, row){
            this.dialogVisible = true
            this.dialogTitle = "编辑"
            this.$nextTick(function(){
                this.$refs['addQuery'].resetFields();
                this.newQuery = Object.assign({}, row)        
            })
        },
        // 新增或编辑之后的确定事件
        handleSure(){
            this.$refs["addQuery"].validate(valid =>{
                if(valid){
                    console.log(this.newQuery)
                    if(this.newQuery.id){
                        this.loadingOne = true
                        update(this.newQuery).then(res =>{
                            if(res.data.code == code){
                                this.$message({
                                    message: "更新成功",
                                    type: "success"
                                });
                                this.dialogVisible = false
                                this.getSearchList()
                            }else{
                                this.$message({
                                    message: res.data.msg,
                                    type: 'warning'
                                })
                            }
                            this.loadingOne = false
                        }).catch(err =>{
                            this.loadingOne = false
                            this.$message({
                                message: err.message,
                                type: 'warning'
                            })
                        })
                    }else{
                        create(this.newQuery).then(res =>{
                            if(res.data.code == code){
                                this.$message({
                                    message: "新增成功",
                                    type: "success"
                                });
                                this.dialogVisible = false
                                this.getSearchList()
                            }else{
                                this.$message({
                                    message: res.data.msg,
                                    type: 'warning'
                                })
                            }
                        }).catch(err =>{
                            this.$message({
                                message: err.message,
                                type: 'warning'
                            })
                        })
                    }
                }
            })
        },
    },
    mounted(){
        this.getSearchList()
    },
    components:{
    }
}
</script>
<style lang="less">
.search .el-form-item{
    margin-right: 50px;
}
.block{
    margin-top: 10px;
}
.el-pagination{
    float: right;
}
.avatar-uploader {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 178px;
    height: 178px;
}
.avatar-uploader:hover {
    border-color: #409EFF;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}
.avatar {
    width: 178px;
    /* height: 178px; */
    display: block;
}
.dialog-footer{
    text-align: center;
}
.dialog-footer .tips{
    line-height: 40px;
    color: red;
    /* background-color: yellow; */
    font-size: 10px;
    // text-align: right;
}
// 头部
.dialog-ckeditor-bg{
    position: absolute;
    top: 0px;
    left: 0px;
    height: 500px;
    width: 1100px;
    height: 100%;
    width: 100%;
    background-color: white;
}
.ckeditor-header{
    height: 80px;
    width: 1100px;
    display: flex;
    margin: 0 auto;
}
.ckeditor-header-item{
    height: 100%;
}
.ckeditor-title{
    width: 100%;
    line-height: 80px;
    font-size: 20px;
    text-align: center;
    color: rgb(99, 99, 99);
}

.dialog-ckeditor{
    width: 1100px;
    margin: 0 auto;
}


// elementui 表格修改边框后下面还是有一条白线
.el-table::before {
    height: 0;  // 将高度修改为0
}

/* --------- EDITOR STYLES  ---------------------------------------------------------------------------------------- */

.editor__editable,
/* Classic build. */
main .ck-editor[role='application'] .ck.ck-content,
/* Decoupled document build. */
.ck.editor__editable[role='textbox'],
.ck.ck-editor__editable[role='textbox'],
/* Inline & Balloon build. */
.ck.editor[role='textbox'] {
	// width: 100%;
	// background: #fff;
    // background: rebeccapurple;
	font-size: 1em;
	line-height: 1.6em;
	min-height: var(--ck-sample-editor-min-height);
	padding: 1.5em 2em;
}
.ck-content{
    height: 1000px;
}

</style>